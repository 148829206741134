/*
 * Home Styles
 */

.mi-home{
  &-area{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    position: relative;
    z-index: 1;
  }

  &-particle{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  &-content{
    text-align: center;
    padding: 50px 80px;

    h1{
      font-weight: 700;
    }

    p{
      margin-top: 15px;
      font-size: 1.25rem;
      line-height: 2rem;
    }

    .mi-socialicons{
      margin-top: 20px;
    }
  }

  @media #{$layout-laptop}{
    &-content{
      padding: 50px 0px;
    }
  }

  @media #{$layout-notebook}{
    &-content{
      padding: 50px 0px;
    }
  }

  @media #{$layout-tablet}{
    &-content{
      padding: 50px 0px;

      p{
        font-size: 1.15rem;
        line-height: 1.75rem;
      }
    }
  }

  @media #{$layout-mobile-lg}{
    &-content{
      padding: 50px 0px;

      p{
        font-size: 1.08rem;
        line-height: 1.55rem;
      }
    }
  }

  @media #{$layout-mobile-sm}{
    &-content{
      h1{
        span{
          display: block;
        }
      }
    }
  }
}

.sign {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
  margin-top: 30%;
  background-image: radial-gradient(
    ellipse 50% 35% at 50% 50%,
    #1e4b6b,
    transparent
  );
  transform: translate(-50%, -50%);
  letter-spacing: 2;
  left: 50%;
  font-family: "Clip";
  text-transform: uppercase;
  font-size: 6em;
  color: #e6f0ff;
  text-shadow: 0 0 0.6rem #e6f0ff, 0 0 1.5rem #65acff,
    -0.2rem 0.1rem 1rem #65acff, 0.2rem 0.1rem 1rem #65acff,
    0 -0.5rem 2rem #2482ff, 0 0.5rem 3rem #2482ff;
  animation: shine 2s forwards, flicker 3s infinite;
  @media #{$layout-mobile-lg}{
    font-size: 3em;
  }

  @media #{$layout-mobile-sm}{
    font-size: 3em;
}
}

@keyframes blink {
  0%,
  22%,
  36%,
  75% {
    color: #e6f0ff;
    text-shadow: 0 0 0.6rem #e6f0ff, 0 0 1.5rem #65acff,
      -0.2rem 0.1rem 1rem #65acff, 0.2rem 0.1rem 1rem #65acff,
      0 -0.5rem 2rem #2482ff, 0 0.5rem 3rem #2482ff;
  }
  28%,
  33% {
    color: #65acff;
    text-shadow: none;
  }
  82%,
  97% {
    color: #2482ff;
    text-shadow: none;
  }
}

.flicker {
  animation: shine 2s forwards, blink 3s 2s infinite;
}

.fast-flicker {
  animation: shine 2s forwards, blink 10s 1s infinite;
}

@keyframes shine {
  0% {
    color: #6b1839;
    text-shadow: none;
  }
  100% {
    color: #e6f0ff;
    text-shadow: 0 0 0.6rem #e6f0ff, 0 0 1.5rem #65acff,
      -0.2rem 0.1rem 1rem #65acff, 0.2rem 0.1rem 1rem #65acff,
      0 -0.5rem 2rem #2482ff, 0 0.5rem 3rem #2482ff;
  }
}

@keyframes flicker {
  from {
    opacity: 1;
  }

  4% {
    opacity: 0.9;
  }

  6% {
    opacity: 0.85;
  }

  8% {
    opacity: 0.95;
  }

  10% {
    opacity: 0.9;
  }

  11% {
    opacity: 0.922;
  }

  12% {
    opacity: 0.9;
  }

  14% {
    opacity: 0.95;
  }

  16% {
    opacity: 0.98;
  }

  17% {
    opacity: 0.9;
  }

  19% {
    opacity: 0.93;
  }

  20% {
    opacity: 0.99;
  }

  24% {
    opacity: 1;
  }

  26% {
    opacity: 0.94;
  }

  28% {
    opacity: 0.98;
  }

  37% {
    opacity: 0.93;
  }

  38% {
    opacity: 0.5;
  }

  39% {
    opacity: 0.96;
  }

  42% {
    opacity: 1;
  }

  44% {
    opacity: 0.97;
  }

  46% {
    opacity: 0.94;
  }

  56% {
    opacity: 0.9;
  }

  58% {
    opacity: 0.9;
  }

  60% {
    opacity: 0.99;
  }

  68% {
    opacity: 1;
  }

  70% {
    opacity: 0.9;
  }

  72% {
    opacity: 0.95;
  }

  93% {
    opacity: 0.93;
  }

  95% {
    opacity: 0.95;
  }

  97% {
    opacity: 0.93;
  }

  to {
    opacity: 1;
  }
}
